import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ApiService } from 'src/app/services/core/api/api.service';
import { NotificationService } from 'src/app/services/core/notification/notification.service';

@Component({
  selector: 'app-verify-payment',
  templateUrl: './verify-payment.component.html',
  styleUrls: ['./verify-payment.component.sass']
})
export class VerifyPaymentComponent implements OnInit {
  @ViewChild('verifyForm',{ static: true}) public any: NgForm;
  pay: any; form: any; code: any = ''; verify: boolean;
  constructor(private api: ApiService, private router: Router, private rout: ActivatedRoute, private notify: NotificationService) { }

  ngOnInit() {
    this.init();
  }

  getPayment(event) {
    if(event.target.value) {
      const value = event.target.value; this.init();
      this.api.get('check/'+ value).subscribe((data:any)=>{
        this.pay = data; this.api.data = data;
      });
    }
  }


  postVerify() {
    // this.api.post('payment', this.pay).subscribe(data=>{
    //   this.pay = data; this.api.data = data;
    //   // setTimeout(() => { this.getInsurance(this.insure._id); }, 100001);
    //   this.api.show();
      // window.location.href = "payment/confirmation/" + this.pay._id;
      this.router.navigate(['/submission', this.pay.type.toLowerCase(), this.pay.code]);
    // })
  }

  init() {
    this.pay = { code: null, companyid: null, formid: null, fullname: '', amount: 0, mobile: 0, network: '', token: '', status: 'Pending', date: new Date() }
  }

}
