import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule, NgForm, NgModel } from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InsuranceComponent } from './pages/insurance/insurance.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { LoaderComponent } from './pages/loader/loader.component';
import { ConfirmationComponent } from './pages/confirmation/confirmation.component';
import { UndergraduateComponent } from './pages/undergraduate/undergraduate.component';
import { IndexComponent } from './pages/index/index.component';
import { VerifyPaymentComponent } from './pages/verify-payment/verify-payment.component';
import { LayoutComponent } from './pages/layout/layout.component';
import { SubmissionComponent } from './pages/submission/submission.component';
import { PaymentConfirmationComponent } from './pages/payment/payment-confirmation/payment-confirmation.component';
import { CheckStatusComponent } from './pages/check-status/check-status.component';
import { ConferenceComponent } from './pages/conference/conference.component';
import { ICareComponent } from './pages/i-care/i-care.component';
import { MembersComponent } from './pages/members/members.component';
import { PptPaymentComponent } from './pages/ppt-payment/ppt-payment.component';
import { ServicesModule } from './services/services.module';
import { Angular4PaystackModule } from 'angular4-paystack';
import { environment } from 'src/environments/environment';
import { SchemesComponent } from './pages/schemes/schemes.component';
//import { AdminComponent } from './pages/admin/admin.component';

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    InsuranceComponent,
    PaymentComponent,
    ConfirmationComponent,
    UndergraduateComponent,
    IndexComponent,
    VerifyPaymentComponent,
    LayoutComponent,
    SubmissionComponent,
    PaymentConfirmationComponent,
    CheckStatusComponent,
    ConferenceComponent,
    ICareComponent,
    MembersComponent,
    PptPaymentComponent,
    SchemesComponent,
    //AdminComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientModule,
    AppRoutingModule,
    ServicesModule,
    Angular4PaystackModule.forRoot(environment.paystack),
    NgSelectModule
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
