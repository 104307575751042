import { Injectable } from '@angular/core';
import { ApiService } from '../core/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  memberInfo: any
  constructor(
    private _api: ApiService
  ) { }

  async create_member(form) {
    return await new Promise((resolve, reject) => {
      try {
        this._api.post("Integration/CreateMember", form).subscribe((info: any) => {
          resolve({
            message: "created member",
            data: info
          })
        })
      }catch(ex) {
        reject({
          error: ex.message || ex.error || ex
        })
      }
    })
  }

  async get_memeber_info_by_mobilenumber(form: any) {
    return await new Promise((resolve, reject) => {
      try {
        this._api.post("Integration/MemberInfo", form).subscribe((info: any) => {
          this.memberInfo = info
          resolve(this.memberInfo)
        })
      }catch(ex) {
        console.error({
          error: ex.message || ex.error || ex
        })
      }
    })
  }
}
