import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/core/api/api.service';

@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.sass']
})
export class InsuranceComponent implements OnInit {
  @ViewChild('insuranceForm',{ static: false}) public insuranceForm: NgForm;
  packages: any[]; 
  insure: any = {
    fullname: "", firstname: "", lastname: "", othername: "", dateofbirth: new Date(), mobile: "", email: "", occupation: "", address: "", id_type: "", id_number: "", tin: "", package_id: "", 
    institution: { name: "", datefrom: new Date(), dateto: new Date(), degree:"", major:"" },
    program: { campus: "", sponsorship:"", session:"", duration: "", infosource:"", enrollment:"" },
    employment: { name: "", datefrom: new Date(), dateto: new Date(), position:"" },
    details: { cust_type: "", cover: "THIRD PARTY BASIC TARRIF", cover_type: "", vehicle: "", model: "", color: "", no_of_seats: 0, year_of_manufacture: "", chasis_number: "", reg_number: "", body_type: "", worth: 0 }, 
    payment: { amount: 0, mobile: 0 }
  }; 
  package: any = { fixed: true, percentage: 0 };
  institutions:any[] = []; institution: any;
  employers:any[] = []; employer: any;

  maritalStatus = ["Single", "Married", "Divorce", "Widowed"];

  mainProgrammes = {title: 'COVENTRY UNIVERSITY [1-YEAR FULLTIME]',id: 1};
  subProgramme = {title: 'MSc. Supply Chain Management ',parentId: 1};
  mainProgrammeGroups = [
    {title: 'Coventry University [1-year Fulltime]',id: 1},
    {title: 'ANHALT',id: 2},
    {title: 'KNUST [2-years Full time]',id: 3},
    {title: 'University of the West of Scotland [1-year Fulltime]',id: 4},
    {title: 'Aalborg [1-year Fulltime]',id: 5},
    {title: 'CASS Europe, France-Luxembourg [1-year Fulltime]',id: 6}
  ]
  subProgrammeCategories = [
    {title: 'MSc. Supply Chain Management',parentId: 1},
    {title: 'MSc. Engineering and Management',parentId: 1},
    {title: 'MSc. Engineering Project Management',parentId: 1},
    {title: 'MSc. Management Information Systems',parentId: 1},
    {title: 'MSc. Oil and Gas Management',parentId: 1},
    {title: 'MSc. Business Decision Management',parentId: 1},
    {title: 'MSc. Information Technology for Management',parentId: 1},
    {title: 'MBA Finance',parentId: 1},
    {title: 'MBA Petroleum Economics and Finance',parentId: 1},
    {title: 'MBA Logistics',parentId: 1},
    {title: 'MBA International Trade',parentId: 2},
    {title: 'MBA International Trade (Distance Learning)',parentId: 2},
    {title: 'MSc. Telecommunications Engineering',parentId: 3},
    {title: 'MSc. Telecommunications Management',parentId: 3},
    {title: 'MSc. Business Entrepreneurship and Technology',parentId: 3},
    {title: 'MSc. Quality Management',parentId: 4},
    {title: 'MSc. Project Management',parentId: 4},
    {title: 'MSc. Information & Communication Technologies',parentId: 5},
    {title: 'MBA Global Business',parentId: 6},
    {title: 'MBA Global Finance',parentId: 6},
    {title: 'MBA ICT Management',parentId: 6},
    {title: 'MBA Healthcare Management',parentId: 6},
    {title: 'MBA Marketing in a Digital Age',parentId: 6},
    {title: 'MBA Human Resource Management',parentId: 6},
    {title: 'MBA Entrepreneurship and Innovation',parentId: 6},
    {title: 'MBA Global Supply Chain Management',parentId: 6}
  ]
  filterSubProgrammesById(id) {
    return this.subProgrammeCategories.filter(item => item.parentId === id);
  }
 
  constructor(
    private api: ApiService, 
    private router: Router
  ) { }

  ngOnInit() {
    this.getPackages();
  }

  getPackages() {
    this.api.get('packages').subscribe((data:any[])=>{
      this.packages = data;
    })
  }

  getPackage(val) {
    this.api.get('package/' + val).subscribe((data:any)=>{
      this.package = data;
      this.insure.payment.amount = data.amount;
      this.insure.details.cover_type = data.name;
      this.insure.payment.type = data.name;
      this.calculate();
    })
  }

  postInsurance() {
    this.api.post('insurance', this.insure).subscribe(data=>{
      this.insure = data; this.api.data = data;
      this.api.show();
      window.location.href = "payment/" + data._id;
      // this.router.navigate(['payment', data._id]);
    })
  }

  calculate() {
    if(this.insure.details.cover == 'FIRE & THEFT' && this.insure.details.worth > 0) {
      this.insure.payment.amount = this.package.amount + (this.insure.details.worth * (this.package.fire_theft_rate)) / 100;
      if(this.insure.details.no_of_seats > this.package.no_of_seat) {
        this.insure.payment.amount += this.package.extra_seat_charge * (this.insure.details.no_of_seats - this.package.no_of_seat);
      }
    } else if (this.insure.details.cover == 'COMPREHENSIVE' && this.insure.details.worth > 0) {
      this.insure.payment.amount = this.package.amount + (this.insure.details.worth * this.package.percentage) / 100;
      if(this.insure.details.no_of_seats > this.package.no_of_seat) {
        this.insure.payment.amount += this.package.extra_seat_charge * (this.insure.details.no_of_seats - this.package.no_of_seat);
      }
    } else {
      this.insure.payment.amount = this.package.amount;
      if(this.insure.details.no_of_seats > this.package.no_of_seat) {
        this.insure.payment.amount += this.package.extra_seat_charge * (this.insure.details.no_of_seats - this.package.no_of_seat);
      }
    }
  }

  addToArray() {
    this.institutions.push(this.institution);
    this.employers.push(this.employer);
  }

}
