import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../interceptors/auth.interceptor';
import { LoaderInterceptor } from '../interceptors/loader.interceptor';

// import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ApiService } from './core/api/api.service';
import { NotificationService } from './core/notification/notification.service';
import { TransactionsService } from './transactions/transactions.service';
import { AccountsService } from './accounts/accounts.service';
import { Angular4PaystackModule } from 'angular4-paystack';
import { environment } from 'src/environments/environment.prod';


export const providers: Array<any> = [
  ApiService, NotificationService,
  TransactionsService, AccountsService,
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  // { provide: ErrorHandler, useClass: ErrorsHandler },
  { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
]

@NgModule({
	imports: [
    CommonModule, 
    Angular4PaystackModule.forRoot(environment.paystack),
    // NgxIntlTelInputModule,
	],
  providers: providers
})
export class ServicesModule { }
