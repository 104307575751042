import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
    title = 'Pay-Forms';
    location: any;
    routerSubscription: any;

    constructor(private router: Router) {
    }

    ngOnInit(){
        this.recallJsFuntions();
    }

    recallJsFuntions() {
        this.router.events
            .subscribe((event) => {
                if ( event instanceof NavigationStart ) {
                    $('.preloader-area').fadeIn('slow');
                }
            });
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
              $.getScript('assets/js/jquery.bootstrap.wizard.js');
              $.getScript('assets/js/demo.js');
              $.getScript('assets/js/paper-bootstrap-wizard.js');
              $.getScript('assets/js/jquery.validate.min.js');
              $('.preloader-area').fadeOut('slow');
              this.location = this.router.url;
                // console.info(this.location);
            });
    }

    ngOnDestroy() {
        this.routerSubscription.unsubscribe();
    }
}
