import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/core/api/api.service';
import intlTelInput from 'intl-tel-input';

declare let $: any;

@Component({
  selector: 'app-i-care',
  templateUrl: './i-care.component.html',
  styleUrls: ['./i-care.component.sass']
})
export class ICareComponent implements OnInit {

  @ViewChild('submisionForm',{ static: true}) public insuranceForm: NgForm;
  member: any; 
  itil: any

  gender = ["Male", "Female"];
  country = ["Ghana", "Nigeria", "Benin"];
  region = ["Volta", "Greater Accra", "Ashanti"];
  verify = ["ID Card", "Voter's Card", "Passport", "Drivers License"];
  network = ["MTN", "VodaFone", "Airtel/Tigo"];
  maritalStatus: any[] = ["Single", "Married", "Divorce", "Widowed"];
  
  icare: any; 
  beneficiaries: any[] = []; beneficiary: any;
  icareBens:any[] = []; icareBen: any;
  
  payment: any; 
  institutions:any[] = []; institution: any; instite$: any;
  departments:any[] = []; department: any;
  courses:any[] = []; course: any;
  campuses:any[] = []; campus: any;
  employers:any[] = []; employer: any; type: string; code: String;

  



  title = ["Ms", "Mrs", "Mr"];
  // maritalStatus: any[] = ["Single", "Married", "Divorce", "Widowed"];
  nationality = ["Ghana", "Nigeria", "Benin"];
  // campus = ["Accra","Ho","Koforidua","Kumasi","Takoradi",];
  sponsorship = ["Employer","Self","Other"];
  duration = ["Full Time [one Year]","Part Time [2-3 years]","Full Time [ two years]"];
  ad = ["Website", "Television", "Recommendation", "Radio", "Friends", "Brochures", "Outreach", "Education Fair"];
  enrollment = ["January", "May", "September"];
  session = ["Weekend", "Evening"];

  // filterSubProgrammesById(id) {
  //   return this.subProgrammeCategories.filter(item => item.parentId === id);
  // }
 
  constructor(
    private api: ApiService, private rout: ActivatedRoute, 
    private router: Router
  ) { 
    this.payment = this.rout.snapshot.data.data;
    console.log(this.payment);
    // if(this.payment != null && this.payment.departments != null && this.payment.courses != null && this.payment.campuses != null) {
    //   this.departments = this.payment.departments.filter(d => d.status == true && d.formid.includes(this.payment.formid));
    //   this.courses = this.payment.courses.filter(d => d.status == true && d.formid.includes(this.payment.formid));
    //   this.campuses = this.payment.campuses.filter(d => d.status == true);
    // } else{
    //   this.router.navigate(['/verify-voucher']);
    // }
  }

  ngOnInit() {
    for(let i = 0; i < 3; i++) {
      intlTelInput(document.querySelector("#mobile" + i), {
        nationalMode: true,
        placeholderNumberType: "MOBILE",
        preferredCountries: ['gh', 'ng'],
        separateDialCode: true,
      });
    }
    this.init();
    // this.initE();
    // this.initS();
    // this.getDepartment();
    $('.display').DataTable( {
      "paging": false,
      "ordering": false,
      "info": false,
      "searching": false,
    });
  }

  getDepartments() {
    this.api.get('department/'+this.payment.formid).subscribe((data:any[])=>{
      this.department = data;
    })
  }
  
  getDepartment(event) {
    if(event.target.value) {
      const value = event.target.value;
      const dpt = this.payment.departments.find(d => d._id == value);
      console.info(dpt); 
      if(dpt){
        this.department = dpt;
      }
      this.courses = this.payment.courses.filter(d => d.status == true && d.formid.includes(this.payment.formid) && d.departmentid == value);
      console.info(this.courses);
      // this.api.get('department/'+this.payment.formid+'/'+ value).subscribe((data:any)=>{
      //   this.courses = data.courses;
      //   this.department = data;
      // });
    }
  }
  getCourse(event) {
    if(event.target.value) {
      const value = event.target.value;
      const cour = this.payment.courses.find(d => d._id == value);
      console.info(cour);
      if(cour){
        this.course = cour;
      }
      // this.api.get('department/'+this.payment.formid+'/'+ value).subscribe((data:any)=>{
      //   this.courses = data.courses;
      //   this.department = data;
      // });
    }
  }

  // getCourse(val) {
  //   this.api.get('course/' + val).subscribe((data:any)=>{
  //     this.course = data;
  //   })
  // }

  postSubmission() {
    this.icare.institutions = this.institutions;
    this.icare.employers = this.employers;
    this.api.post('submission', this.icare).subscribe(data=>{
      this.icare = data; this.api.data = data;
      this.api.show();
      // window.location.href = "payment/" + data._id;
      this.router.navigate(['/submission',this.icare.type.toLowerCase(), this.icare.code, 'confirmation']);
    })
  }

  addToInst() {
    this.institutions.push(this.institution);
    // this.employers.push(this.employer);

    // this.initS();

    // $('#instite').DataTable().destroy();
    // $('#instite').DataTable( {
    //   "paging":   false,
    //   "ordering": false,
    //   "info":     false
    // });
  }

  // addToEmp() {
  //   this.employers.push(this.employer);
  //   this.initE();
  // }

  addToBen() {
    this.icareBens.push(this.icareBen);
    this.initB();
  }

  initSTs() {
    $('.display').DataTable( {
      "paging":   false,
      "ordering": false,
      "info":     false
    });
  }

  // addToEmployers() {
  //   this.institutions.push(this.institution);
  //   this.initE();
  //   // this.employers.push(this.employer);
  // }

  init() {
    this.icare = { 
      // code: this.payment.code, type: this.payment.type,
      icarename: "", icaremobile: "", icareemail: "", totalamount: "", network: "",
      // beneficiaries: [], 
      icareBens: [],

      // title: "", fullname: "", dateofbirth: new Date(), mobile: "", email: "", occupation: "", address: "", nationality: "", maritalstatus: "",
      // institutions: [], courseid: "", departmentid: "", campusid: "", sponsorship:"", session:"", duration: "", adsource:"", enrollment:"", 
      // employers: [], companyid: this.payment.companyid
    }
    this.initB();
    // this.initS();
    // this.initE();
  }
  initB() {
    this.icareBen = { firstname: "", middlename: "", lastname: "",  mobile: "",  amount: "" };
  }
  // initS() {
  //   this.institution = { name: "", datefrom: null, dateto: null, degree:"", major:"" };
  // }
  // initE() {
  //   this.employer = { name: "", datefrom: null, dateto: null, position:"" };
  // }

}














