import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './pages/layout/layout.component';
import { InsuranceComponent } from './pages/insurance/insurance.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { PaymentConfirmationComponent } from './pages/payment/payment-confirmation/payment-confirmation.component';
import { VerifyPaymentComponent } from './pages/verify-payment/verify-payment.component';
import { ConfirmationComponent } from './pages/confirmation/confirmation.component';
import { UndergraduateComponent } from './pages/undergraduate/undergraduate.component';
import { IndexComponent } from './pages/index/index.component';
import { SubmissionComponent } from './pages/submission/submission.component';
import { CheckStatusComponent } from './pages/check-status/check-status.component';

import { Resolver, VoucherResolver, StatusResolver, PaymentResolver, SubmissionResolver } from './services/resolver.service';
import { ConferenceComponent } from './pages/conference/conference.component';
import { ICareComponent } from './pages/i-care/i-care.component';
import { MembersComponent } from './pages/members/members.component';
import { PptPaymentComponent } from './pages/ppt-payment/ppt-payment.component';
import { SchemesComponent } from './pages/schemes/schemes.component';


const routes: Routes = [
  { path: '', component: LayoutComponent, children: [
    { path: '', component: IndexComponent },


    { path: 'members', component: MembersComponent },
    { path: 'schemes', component: SchemesComponent },
    { path: 'icare', component: ICareComponent },
    { path: 'payment', component: PptPaymentComponent },
    // { path: 'under-graduate', component: UndergraduateComponent },


    // { path: 'forms/:code', component: InsuranceComponent },
    // { path: 'under-graduate', component: UndergraduateComponent },
    { path: 'index', component: IndexComponent },
    // { path: 'payment', component: PaymentComponent },
    { path: 'payment/:id', component: PaymentComponent },
    // { path: 'confirmation/:code', component: PaymentConfirmationComponent, resolve: { data: PaymentResolver }, runGuardsAndResolvers: 'always'  },
    // { path: 'buy-voucher', component: PaymentComponent },
    // { path: 'buy-voucher/confirmation/:code', component: PaymentConfirmationComponent, resolve: { data: PaymentResolver }, runGuardsAndResolvers: 'always'  },
    // { path: 'verify-voucher', component: VerifyPaymentComponent },
    // // { path: 'submission/under-graduate/:code', component: UndergraduateComponent },
    // { path: 'submission/undergraduate/:code', component: UndergraduateComponent, resolve: { data: VoucherResolver }, runGuardsAndResolvers: 'always' },
    // { path: 'submission/diploma/:code', component: UndergraduateComponent, resolve: { data: VoucherResolver }, runGuardsAndResolvers: 'always' },
    // // { path: 'submission/:type/:code', component: SubmissionComponent },
    // { path: 'submission/:type/:code', component: SubmissionComponent, resolve: { data: VoucherResolver }, runGuardsAndResolvers: 'always' },
    // { path: 'submission/:type/:code/confirmation', component: ConfirmationComponent, resolve: { data: StatusResolver }, runGuardsAndResolvers: 'always' },
    // { path: 'check-status', component: CheckStatusComponent },
    // { path: 'check-status/:code', component: ConfirmationComponent, resolve: { data: StatusResolver }, runGuardsAndResolvers: 'always' },
    // { path: 'conference', component: ConferenceComponent },
  ]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
