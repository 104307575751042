// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // api: 'http://localhost:8000/',
  // apiUrl: 'http://localhost:8000/app/',
  // imageUrl: 'http://localhost:8000/public/',
  // apiUrl: 'https://maximus-ussd-api.herokuapp.com/test/',
  // apiUrl: 'https://app.alias-solutions.net:5008/',
  // paystack: 'pk_test_ead35a8a808544405c43e3b0429f8db648026e9c',
  // imageUpload: 'https://app.alias-solutions.net:5008/',
  // imageUrl: 'https://app.alias-solutions.net:5008/Files/',
  // api_values: {
  //   AppId: "23456789",
  //   AppKey: "56456789",
  // },
  apiUrl: 'https://app.alias-solutions.net:5009/',
  paystack: 'pk_live_1cfeb7dc975a87834a631660709e6835bcfd4511',
  imageUpload: 'http://app.alias-solutions.net:5009/',
  imageUrl: 'https://app.alias-solutions.net:5009/Files/',
  api_values: {
    AppId: "PPT-DIASPORA",
    AppKey: "6535876665",
  },
  firebaseConfig: {
    apiKey: "AIzaSyDob7yUDkfSCqXGxc3u7cashpoSE_3GN8c",
    authDomain: "ppt-apps.firebaseapp.com",
    projectId: "ppt-apps",
    storageBucket: "ppt-apps.appspot.com",
    messagingSenderId: "146070870438",
    appId: "1:146070870438:web:6a6e2116ec64e6e596d767",
    measurementId: "G-2E16D839TQ"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
