import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/core/api/api.service';
import { NotificationService } from 'src/app/services/core/notification/notification.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.sass']
})
export class ConfirmationComponent implements OnInit {
  pay: any; otp: any;
  constructor(private api: ApiService, private router: Router, private rout: ActivatedRoute, private notify: NotificationService) { 
    this.pay = this.rout.snapshot.data.data;
    if(this.pay == null) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    // if(this.rout.snapshot.paramMap.has('id')) {
    //   this.getPayment(this.rout.snapshot.paramMap.get('id'));
    // }
  }

  getPayment(val) {
    this.api.get('submission/' + val).subscribe((data:any)=>{
      this.pay = data;
    })
  }

}
