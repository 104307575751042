import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from '../core/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {

  transInfo: any = {
    ref: `PPTD${Math.ceil(Math.random() * 10e13)}`,
    channel: ['card'],
    amount: 0.00,
    currency: 'GHS',
    email: null
  }
  constructor(
    private _api: ApiService
  ) { }

  async paystack_transaction_init(form) {
    return await new Promise((resolve, reject) => {
      try {
        this.transInfo.amount = form.amount
        this.transInfo.email = form.email
      }catch(ex) {
        reject({
          error: ex.message || ex.error || ex
        })
      }
    })
  }

  paystack_transaction_cancelled(form) {
    
  }

  async save_transactions(form) {
    return await new Promise((resolve, reject) => {
      try {
        form['Method'] = form['NetWork'] = "card"
        form['Source'] = "disapower"
        form['Reference'] = "Reference"

        let url: string = `Integration/Deposit/Card/${environment.api_values.AppId}/${environment.api_values.AppKey}`
        this._api.post(url, form).subscribe((info: any) => {
          console.info(info)
          resolve('payment recorded')
        })
      }catch(ex) {
        reject({
          error: ex.message || ex.error || ex
        })
      }
    })
  }
}
