import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-schemes',
  templateUrl: './schemes.component.html',
  styleUrls: ['./schemes.component.sass']
})
export class SchemesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
