import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsService } from 'src/app/services/accounts/accounts.service';
import { ApiService } from 'src/app/services/core/api/api.service';
import { NotificationService } from 'src/app/services/core/notification/notification.service';
import { TransactionsService } from 'src/app/services/transactions/transactions.service';
import { environment } from 'src/environments/environment';
import intlTelInput from 'intl-tel-input';

declare let $: any;

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.sass']
})
export class MembersComponent implements OnInit {

  member: any = { 
    firstname: "", 
    middlename: "", 
    lastname: "", 
    frequency: "ONETIME", 
    maidenname: "", 
    dateofbirth: new Date(), 
    gender: "Male", landmark: "",
    country: "Ghana", placeofbirth:"", occupation: "", verification: "", idnum: "", expdate: new Date(), 
    mobile: "", email: "",  address: "", paddress: "", hometown: "", maritalstatus: "", amount: "",
  }

  gender = ["Male", "Female"];
  country = ["Ghana", "Nigeria", "Benin"];
  region = ["Volta", "Greater Accra", "Ashanti"];
  verify = ["ID Card", "Voter's Card", "Passport", "Drivers License"];
  network = ["MTN", "VodaFone", "Airtel/Tigo"];
  maritalStatus: any[] = ["Single", "Married", "Divorce", "Widowed"];
  
  
  type: string; 
  code: String;

  title = ["Ms", "Mrs", "Mr"];
  // maritalStatus: any[] = ["Single", "Married", "Divorce", "Widowed"];
  nationality = ["Ghana", "Nigeria", "Benin"];
  // campus = ["Accra","Ho","Koforidua","Kumasi","Takoradi",];
  sponsorship = ["Employer","Self","Other"];
  duration = ["Full Time [one Year]","Part Time [2-3 years]","Full Time [ two years]"];
  ad = ["Website", "Television", "Recommendation", "Radio", "Friends", "Brochures", "Outreach", "Education Fair"];
  enrollment = ["January", "May", "September"];
  session = ["Weekend", "Evening"];
  itil: any

 
  constructor(
    private router: Router,
    private _acct: AccountsService,
    private _notify: NotificationService,
    public _trans: TransactionsService
  ) { 
  }

  ngOnInit() {
    const input = document.querySelector("#phone");
    this.itil = intlTelInput(input, {
      nationalMode: true,
      // placeholderNumberType: "MOBILE",
      preferredCountries: ['gh', 'ng'],
      separateDialCode: true,
    });
  }

  async save_member(form) {
    let number = this.member.mobile.split('')
    if(number[0] == 0) delete number[0];
    this.member.mobile = number.join('')

    form['Mobile'] = `+${this.itil.selectedCountryData.dialCode}${this.member.mobile}`;
    form['Frequency'] = this.member.frequency
    form['Amount'] = this.member.amount
    form['Email'] = this.member.email

    alert(form['Mobile'])

    const resp: any = await this._acct.create_member( {
      "AppId": environment.api_values.AppId,
      "AppKey": environment.api_values.AppKey,
      "FirstName": this.member.firstname,
      "LastName": this.member.lastname,
      "MiddleName": this.member.middlename,
      "DateOfBirth": this.member.dateofbirth,
      "Email": this.member.email,
      "Gender": this.member.gender,
      "IdNumber": this.member.idnum,
      "IdType": this.member.verification,
      "Mobile": form['Mobile'],
      "Source": "APP",
      "MaritalStatus": this.member.maritalstatus,
      "Address": this.member.address
    })
    if(resp.error) this._notify.notify(resp.error)
    if(resp.message && resp.message == 'created member') {
      form['Account'] = resp.data.Scheme.SchemeNumber
      form['tranid'] = form.trxref
      form['transaction_no'] = form.transaction
  
      const res: any = await this._trans.save_transactions(form)
      if(res == 'payment recorded') {
        this._notify.notify("New member created successfully");
        this.router.navigateByUrl(`/`)
        // this.router.navigateByUrl(`/confirmation/${form['referrence']}`)
      }
    }
  }
}







