import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TransactionsService } from 'src/app/services/transactions/transactions.service';
import { AccountsService } from 'src/app/services/accounts/accounts.service';
import { environment } from 'src/environments/environment.prod';
import { NotificationService } from 'src/app/services/core/notification/notification.service';
import { Router } from '@angular/router';
import intlTelInput from 'intl-tel-input';

@Component({
  selector: 'app-ppt-payment',
  templateUrl: './ppt-payment.component.html',
  styleUrls: ['./ppt-payment.component.sass']
})
export class PptPaymentComponent implements OnInit {

  @ViewChild('paymentForm',{ static: true}) public any: NgForm;
  pay: any = { 
    code: null, 
    type: '', 
    companyid: null, 
    formid: null, 
    name: '', 
    email: null, 
    amount: 0, 
    schemenumber: null, 
    mobile: '', 
    frequency: 'ONETIME', 
    status: 'Pending', 
    date: new Date() 
  }; 
  itil: any
  mobile: any

  constructor(
    private router: Router,
    private notify: NotificationService,
    public _trans: TransactionsService,
    private _acct: AccountsService
  ) {
  }

  ngOnInit() {
    this.itil = intlTelInput(document.querySelector("#phone"), {
      nationalMode: true,
      placeholderNumberType: "MOBILE",
      separateDialCode: true,
      preferredCountries: ['gh','ng'],
    });
  }

  async validate_mobile_number() {
    let number = this.pay.mobile.split('')
    if(number[0] == 0) delete number[0];
    this.pay.mobile = number.join('')
    this.mobile = `+${this.itil.selectedCountryData.dialCode}${this.pay.mobile}`;
    // console.log(this.mobile)
    await this._acct.get_memeber_info_by_mobilenumber({
      Mobile: this.mobile,
      AppId: environment.api_values.AppId,
      AppKey: environment.api_values.AppKey,
    });
    this.pay.email = this._acct.memberInfo.email || ' ';
    this.pay.name = this._acct.memberInfo.fullname || ''
    this.pay.schemenumber = this._acct.memberInfo.scheme.schemenumber || ''
    if(this._acct.memberInfo && this._acct.memberInfo.fullname !=null && this._acct.memberInfo.scheme == null) this.notify.error("Mobile Number does not have a Personal Pension Scheme");
  }

  async register(form: any) {
    form['Account'] = this.pay.schemenumber
    form['Mobile'] = this.mobile
    form['Frequency'] = this.pay.frequency
    form['Amount'] = this.pay.amount
    form['Email'] = this.pay.email
    form['tranid'] = form.trxref
    form['transaction_no'] = form.transaction

    const resp: any = await this._trans.save_transactions(form)
    if(resp == 'payment recorded') {
      this.notify.notify(resp)
      this.router.navigateByUrl(`/`);
    }
  }


}





