export const environment = {
  production: true,  
  // api: 'https://eforms-api.herokuapp.com/',
  // apiUrl: 'https://eforms-api.herokuapp.com/app/',
  // apiUrls: 'https://api.paynowafrica.com/paynow/otp/',
  // apiUrl: 'https://maximus-ussd-api.herokuapp.com/test/',
  apiUrl: 'https://app.alias-solutions.net:5009/',
  paystack: 'pk_live_1cfeb7dc975a87834a631660709e6835bcfd4511',
  imageUpload: 'http://app.alias-solutions.net:5009/',
  imageUrl: 'https://app.alias-solutions.net:5009/Files/',
  api_values: {
    AppId: "PPT-DIASPORA",
    AppKey: "6535876665",
  },
  firebaseConfig: {
    apiKey: "AIzaSyDob7yUDkfSCqXGxc3u7cashpoSE_3GN8c",
    authDomain: "ppt-apps.firebaseapp.com",
    projectId: "ppt-apps",
    storageBucket: "ppt-apps.appspot.com",
    messagingSenderId: "146070870438",
    appId: "1:146070870438:web:6a6e2116ec64e6e596d767",
    measurementId: "G-2E16D839TQ"
  }
};
