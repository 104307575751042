import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/core/api/api.service';

@Component({
  selector: 'app-conference',
  templateUrl: './conference.component.html',
  styleUrls: ['./conference.component.sass']
})
export class ConferenceComponent implements OnInit {
  ug: any; payment: any; 
  institutions:any[] = []; institution: any; instite$: any;
  departments:any[] = []; department: any;
  courses:any[] = []; course: any;
  campuses:any[] = []; campus: any;
  employers:any[] = []; employer: any; type: string; code: String;
  subjects:any[] = []; subject: any;
  

  
  title = ["Bro", "Sis", "Rev", "Very Rev", "Rev Dr", "Very Rev Dr", "Right Rev", "Right Rev Dr"];
  statusType = ["Minister", "Lay", "Visitor"];
  category = ["Delegate", "Observer"];
  circuit = ["Abeka", "Abossey Okai", "Accra", "Accra Newtown", "Accra North", "Accra South", "Adabraka",
              "Bubiashie", "Dansoman", "Dansoman South", "Kaneshie", "Kaneshie North", "Kokomlemle", "Korle Gonno",
              "Kpeehe", "Kwashieman", "La", "Laterbiokorshie", "Mamprobi", "Mamprobi South", "New Aplaku", 
              "Nungua", "Osu", "Teshie"];
  circuitOrg = ["WOMEN'S FELLOWSHIP", "MENS FELLOWSHIP","CHURCH CHOIR -GHAMEC","SINGING BAND", 
                "SUSANNA WESLEY MISSION AUXILIARY (SUWMA)", "CHRIST'S LITTLE BAND", "METHODIST GUILD",
                "METHODIST YOUTH FELLOWSHIP", "BRIGADE", "GIRLS' FELLOWSHIP"];
  


  
  constructor(
    private api: ApiService, private rout: ActivatedRoute, 
    private router: Router) { 
      //this.payment = this.rout.snapshot.data;
      // this.payment = this.rout.snapshot.data.data;
      // console.log(this.payment);
      // if(this.payment != null && this.payment.departments != null && this.payment.courses != null && this.payment.campuses != null) {
      //   this.departments = this.payment.departments.filter(d => d.status == true && d.formid.includes(this.payment.formid));
      //   this.courses = this.payment.courses.filter(d => d.status == true && d.formid.includes(this.payment.formid));
      //   this.campuses = this.payment.campuses.filter(d => d.status == true);
      // } else{
      //   this.router.navigate(['/verify-voucher']);
      // }
  }

  ngOnInit() {
    // this.init();
  }

  getDepartments() {
    this.api.get('department/'+this.payment.formid).subscribe((data:any[])=>{
      this.department = data;
    })
  }
  
  getDepartment(event) {
    if(event.target.value) {
      const value = event.target.value;
      const dpt = this.payment.departments.find(d => d._id == value);
      console.info(dpt); 
      if(dpt){
        this.department = dpt;
      }
      this.courses = this.payment.courses.filter(d => d.status == true && d.formid.includes(this.payment.formid) && d.departmentid == value);
      console.info(this.courses);
    //   this.api.get('department/'+this.payment.formid+'/'+ value).subscribe((data:any)=>{
    //     this.courses = data.courses;
    //     this.department = data;
    //   });
    }
  }

  getCourse(event) {
    if(event.target.value) {
      const value = event.target.value;
      const cour = this.payment.courses.filter(d => d._id == value);
      console.info(cour);
      if(cour){
        this.course = cour;
      }
      // this.api.get('department/'+this.payment.formid+'/'+ value).subscribe((data:any)=>{
      //   this.courses = data.courses;
      //   this.department = data;
      // });


      // this.api.get('course/' + val).subscribe((data:any)=>{
      //   this.course = data;
      // })
    }
  }

  postUG() {
    this.ug.subjects = this.subjects;
    this.api.post('submission', this.ug).subscribe(data=>{
      this.ug = data; this.api.data = data;
      this.api.show();
      // window.location.href = "payment/" + data._id;
      // this.router.navigate(['confirmation', data._id]);
      this.router.navigate(['/submission',this.ug.type.toLowerCase(), this.ug.code, 'confirmation']);
    })
  }

 


  // addToArray() {
  //   this.institutions.push(this.institution);
  //   this.employers.push(this.employer);
  // }

  init() {
    this.ug = { code: this.payment.code, type: this.payment.type,
      title: "", fullname: "", firstname: "", lastname: "", othername: "", dateofbirth: new Date(), mobile: "", 
      email: "", occupation: "", address: "",  hostel:"", disability:"", disabilityexpl:"",
      campusid: "", sponsorship:"", session:"", duration: "", infosource:"", enrollment:"",
      certificate: "", dateawarded: new Date(), courseid: "", departmentid: null,
      programchoice: "", companyid: this.payment.companyid
    }

  }



}
















