import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ApiService } from 'src/app/services/core/api/api.service';
import { NotificationService } from 'src/app/services/core/notification/notification.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.sass']
})
export class PaymentComponent implements OnInit {
  @ViewChild('paymentForm',{ static: true}) public any: NgForm;
  pay: any; form: any; institution: any; institutions: any[] = []; forms: any[] = []; otp: any; verify: boolean;
  constructor(private api: ApiService, private router: Router, private rout: ActivatedRoute, private notify: NotificationService) { }

  ngOnInit() {
    this.init();
    this.getInstitutions();
  }

  getInstitutions() {
    this.api.get('institutions').subscribe((data:any[])=>{
      this.institutions = data;
    })
  }

  getInstitution(event) {
    if(event.target.value) {
      const value = event.target.value;
      this.api.get('institution/'+ value).subscribe((data:any)=>{
        this.forms = data.forms;
        this.pay.code = data.code;
        this.institution = data;
      });
    }
  }

  getForm(event) {
    if(event.target.value) {
      const value = event.target.value;
      this.api.get('form/'+ value).subscribe((data:any)=>{
        this.form = data;
        this.pay.amount = data.amount; this.pay.type = data.name;
      });
    }
  }

  postPayment() {
    // this.pay.type = this.form.type;
    this.api.post('payment', this.pay).subscribe(data=>{
      this.api.data = data.payment;
      // setTimeout(() => { this.getInsurance(this.insure._id); }, 100001);
      this.api.show();
      // window.location.href = "payment/confirmation/" + this.pay._id;
      this.router.navigate(['/buy-voucher/confirmation', data.payment.code]);
    })
  }

  Verify() {
    this.api.get('verify/'+this.pay.mobile+'/'+ this.pay.token +'?code='+this.institution.code+'&id=Eform').subscribe((data:any)=>{
      this.verify = data.status
      this.notify.success({output: 'Verification Code', message: data.message})
    });
  }

  sendOtp() {
    this.pay.token = "";
    this.api.get(this.pay.mobile+'/Pay-Eform?code='+this.institution.code+'&id=Eform').subscribe((data:any)=>{
      this.otp = data;
      this.notify.success({output: 'Verification Code', message: data.message})
    });
  }

  init() {
    // this.insure = {
    //   firstname: "", lastname: "", othername: "", dateofbirth: "", mobile: "", email: "", occupation: "", address: "", id_type: "", id_number: "", tin: "", packageid: "", status: "Pending", 
    //   details: { cust_type: "", cover_type: "Taxi Commercial", vehicle: "", model: "", color: "", no_of_seats: "", year_of_manufacture: "", chasis_number: "", reg_number: "", body_type: "" }, 
    //   payment: { amount: 0, mobile: 0, network: '', token: '' },
    //   formDetails: {institution:'', course:'', form:'' }
    // };
    this.pay = { code: null, type: '', companyid: null, formid: null, name: '', amount: 0, mobile: '', network: '', token: '', status: 'Pending', date: new Date() }
  }
}
