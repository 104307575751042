import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/core/api/api.service';

@Component({
  selector: 'app-undergraduate',
  templateUrl: './undergraduate.component.html',
  styleUrls: ['./undergraduate.component.sass']
})
export class UndergraduateComponent implements OnInit {
  ug: any; payment: any; 
  institutions:any[] = []; institution: any; instite$: any;
  departments:any[] = []; department: any;
  courses:any[] = []; course: any;
  campuses:any[] = []; campus: any;
  employers:any[] = []; employer: any; type: string; code: String;
  subjects:any[] = []; subject: any;
  

  certcourses = ["Mathematics", "English", "Litreature", "Integrated Science", "Accounting"];
  grade = ["A", "B2", "B3", "C4", "C5", "C6", "D7", "E8", "F9"];
  title = ["Ms", "Mrs", "Mr"];
  maritalStatus: any[] = ["Single", "Married", "Divorce", "Widowed"];
  nationality = ["Ghana", "Nigeria", "Benin"];
  //campus = ["Accra","Ho","Koforidua","Kumasi","Takoradi",];
  sponsorship = ["Employer","Self","Other"];
  duration = ["Full Time [one Year]","Part Time [2-3 years]","Full Time [ two years]"];
  ad = ["Website", "Television", "Recommendation", "Radio", "Friends", "Brochures", "Outreach", "Education Fair"];
  enrollment = ["January", "May", "September"];
  session = ["Weekend", "Evening"];


  
  constructor(
    private api: ApiService, private rout: ActivatedRoute, 
    private router: Router) { 
      //this.payment = this.rout.snapshot.data;
      this.payment = this.rout.snapshot.data.data;
      console.log(this.payment);
      if(this.payment != null && this.payment.departments != null && this.payment.courses != null && this.payment.campuses != null) {
        this.departments = this.payment.departments.filter(d => d.status == true && d.formid.includes(this.payment.formid));
        this.courses = this.payment.courses.filter(d => d.status == true && d.formid.includes(this.payment.formid));
        this.campuses = this.payment.campuses.filter(d => d.status == true);
      } else{
        this.router.navigate(['/verify-voucher']);
      }
  }

  ngOnInit() {
    this.init();
  }

  getDepartments() {
    this.api.get('department/'+this.payment.formid).subscribe((data:any[])=>{
      this.department = data;
    })
  }
  
  getDepartment(event) {
    if(event.target.value) {
      const value = event.target.value;
      const dpt = this.payment.departments.find(d => d._id == value);
      console.info(dpt); 
      if(dpt){
        this.department = dpt;
      }
      this.courses = this.payment.courses.filter(d => d.status == true && d.formid.includes(this.payment.formid) && d.departmentid == value);
      console.info(this.courses);
    //   this.api.get('department/'+this.payment.formid+'/'+ value).subscribe((data:any)=>{
    //     this.courses = data.courses;
    //     this.department = data;
    //   });
    }
  }

  getCourse(event) {
    if(event.target.value) {
      const value = event.target.value;
      const cour = this.payment.courses.filter(d => d._id == value);
      console.info(cour);
      if(cour){
        this.course = cour;
      }
      // this.api.get('department/'+this.payment.formid+'/'+ value).subscribe((data:any)=>{
      //   this.courses = data.courses;
      //   this.department = data;
      // });


      // this.api.get('course/' + val).subscribe((data:any)=>{
      //   this.course = data;
      // })
    }
  }

  postUG() {
    this.ug.subjects = this.subjects;
    this.api.post('submission', this.ug).subscribe(data=>{
      this.ug = data; this.api.data = data;
      this.api.show();
      // window.location.href = "payment/" + data._id;
      // this.router.navigate(['confirmation', data._id]);
      this.router.navigate(['/submission',this.ug.type.toLowerCase(), this.ug.code, 'confirmation']);
    })
  }

  addToSubject() {
    this.subjects.push(this.subject);
    this.initS();
  }


  // addToArray() {
  //   this.institutions.push(this.institution);
  //   this.employers.push(this.employer);
  // }

  init() {
    this.ug = { code: this.payment.code, type: this.payment.type,
      title: "", fullname: "", firstname: "", lastname: "", othername: "", dateofbirth: new Date(), mobile: "", 
      email: "", occupation: "", address: "",  hostel:"", disability:"", disabilityexpl:"",
      campusid: "", sponsorship:"", session:"", duration: "", infosource:"", enrollment:"",
      certificate: "", dateawarded: new Date(), courseid: "", departmentid: null,
      programchoice: "", companyid: this.payment.companyid
    }
    this.initS();
  }

  initS() {
    this.subject = { name: "", grade:""};
  }

}




