import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/core/api/api.service';
import { NotificationService } from 'src/app/services/core/notification/notification.service';

@Component({
  selector: 'app-payment-confirmation',
  templateUrl: './payment-confirmation.component.html',
  styleUrls: ['./payment-confirmation.component.sass']
})
export class PaymentConfirmationComponent implements OnInit {
  pay: any; otp: any;
  constructor(private api: ApiService, private router: Router, private rout: ActivatedRoute, private notify: NotificationService) { 
    this.pay = this.rout.snapshot.data.data;
    if(this.pay == null) {
      this.router.navigate(['/buy-voucher']);
    }
  }

  ngOnInit() {
    if(this.rout.snapshot.paramMap.has('id')) {
      this.getPayment(this.rout.snapshot.paramMap.get('id'));
    }
  }

  getPayment(val) {
    this.api.get('payment/' + val).subscribe((data:any)=>{
      this.pay = data;
      if(data.callback) {
        if(data.status_code == 1) {
          this.notify.success({ output: "Paid", message: "Payment is completed successfully"});
          // window.location.href = "/";
        } else if(data.callback.status_code == 0) {
          setTimeout(() => { this.getPayment(this.pay._id); }, 100000);
        } else {
          this.notify.error({ output: "Fail", message: "Payment not successful, try again"});
          // window.location.href = "payment/" + data._id;
          this.router.navigate(['/buy-voucher']);
        }
      }
    })
  }

}
